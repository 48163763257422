import React from 'react'
import { Link } from 'gatsby'

export default function Footer() {
  return (
    <div id='footer'>
      <div className='inner'>
        <div className='row'>
          <Link to='/resources/medical-information'>Contact Us</Link>
          <a 
          target='_blank'
          rel='noopener noreferrer'
          className='white-list'
          href='https://us.pharmaessentia.com/terms-of-use/'>Terms of Use</a>
          <a 
          target='_blank'
          rel='noopener noreferrer'
          className='white-list'
          href='https://us.pharmaessentia.com/privacy-notice/'>Privacy Policy</a>
        </div>
        <div className='row'>
          <Link to='/'><img src='/svg/pharmaessentia-green-logo.svg' alt='PharmaEssentia logo'/></Link>
        </div>
        
        <p>This site and all materials are intended for US healthcare professionals as a nonpromotional resource for medical and scientific information and noncertified medical education purposes only.</p>
        <ul>
          <li>PharmaEssentia USA Corporation</li>
          <li>35 Corporate Drive, Suite 325,</li>
          <li>Burlington, MA 01803, USA</li>
        </ul>
        <ul>
          <li>&copy; 2023 PharmaEssentia Corporation. All rights reserved.</li>
          <li>PharmaEssentia and the PharmaEssentia logo are trademarks or registered trademarks of PharmaEssentia Corporation.</li>
          <li>MED-US-RPEG-2200019 (v1.0) 06/2023</li>
        </ul>
      </div>
      
    </div>  
  )
}
