import React from 'react'
import { useEffect } from 'react'

export default function ConfirmExit({children}) {

    useEffect(() => {
        //  select all links that are navigating away from the site
        //  const   links = document.querySelectorAll('a[href^="http"]')
        //  filter out white-list 
        const   links = document.querySelectorAll('a[href^="http"]:not(a.white-list):not(a.confirm)'),
                confirmExit = document.getElementById('confirm-exit'),
                closeBtn = document.getElementById('confirm-exit-close'),
                confirmedBtn = document.getElementById('confirm-exit-confirmed'), 
                handleLeave = (event) => {
                    event.preventDefault();
                    console.log(event.target)
                    confirmExit.className = 'show'
                    confirmedBtn.href = event.target.href
                },
                closeConfirmExit = () => {
                    confirmExit.className = ''
                }
        
        // Add event listener for when the user clicks a link that starts with 'http'
        links.forEach(link => link.addEventListener('click', (event)=>{handleLeave(event)}))
        
        //  close
        closeBtn.addEventListener('click', closeConfirmExit)
        confirmedBtn.addEventListener('click', closeConfirmExit)
    }, []);

  return (
    <>
      {children}
        <div id='confirm-exit'>
            <div className='wrapper'>
                <div className='content-wrapper'>
                    <p className='cta'>You are now leaving this site and being directed to a site not maintained by PharmaEssentia.</p>
                    <p>PharmaEssentia is not responsible for content or privacy policies on other sites.</p>
                </div>
                <div className='btn-wrapper'>
                    <button id='confirm-exit-close' className='decline'>Cancel</button>
                    <a
                    id='confirm-exit-confirmed'
                    className='confirm'
                    rel='noreferrer'
                    target='_blank'
                    href='#'>OK</a>
                </div>
            </div>
        </div>
    </>
  );
}
