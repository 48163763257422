import React, { useState, useEffect } from 'react';

export default function ConfirmHCP({children}) {

    const   cookieExist = (name) => {
                var dc = document.cookie;
                var prefix = name + "=";
                var begin = dc.indexOf("; " + prefix);
                if (begin === -1) {
                    begin = dc.indexOf(prefix);
                    if (begin !== 0) return null;
                } else {
                    begin += 2;
                    var end = document.cookie.indexOf(";", begin);
                    if (end !== -1) {
                        end = dc.length;
                    }
                }
                return decodeURI(dc.substring(begin + prefix.length, end));
            }, 
            handleConfirm = (event) => {
                let date = new Date()
                //  one day
                date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000))
                const expires = "; expires=" + date.toUTCString()
                document.cookie = "hcp-confirmed=true" + expires + "; path=/"
                setShowModal(false)
            },
            handleDecline = () => {
                window.open("https://us.pharmaessentia.com/", "_self");
            },
            [showModal, setShowModal] = useState(false)

    useEffect(() => {

        //  check if we have our analytics opt out cookie
        if(cookieExist('hcp-confirmed')) return

        //  show our modal
        setShowModal(true);
    })

    return (
        <>
          {children}
          {showModal && (
                <div id='confirm-hcp'>
                    <div className='wrapper'>
                        <div className='content-wrapper'>
                            <p className='cta'>Are you a US healthcare professional?</p>
                        </div>
                        <div className='btn-wrapper'>
                            <div className='col'>
                                <button className='confirm' id="confirm-hcp-btn" onClick={handleConfirm}>YES</button>
                                <p className='bold'>I AM A US HEALTHCARE/MEDICAL PROFESSIONAL</p>
                                <p>I acknowledge that I am requesting access to this site, including scientific and educational information about PharmaEssentia and its research and development programs.</p>
                            </div>
                            <div className='col'>
                                <button className='decline' onClick={handleDecline}>NO</button>
                                <p className='bold'>I AM NOT A US HEALTHCARE/MEDICAL PROFESSIONAL</p>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
             )}
        </>
    );
}