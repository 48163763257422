import React from 'react'
import CookieNotice from './CookieNotice'

export default function CookieBanner() {

    return (
        <div className='gdpr'>
        <CookieNotice
        backgroundWrapperClasses='inner'
        wrapperClasses='btn-grp'
        personalizeButtonText=''
        personalizeButtonClasses='none'
        buttonWrapperClasses='btn-action-grp'
        declineButtonText='Accept'
        acceptButtonText='Decline'
        cookies={[
            
            {name: 'gatsby-gdpr-google-tagmanager', editable: true, default: true, title: 'Google Analytics', text: 'Google Analytics is a statistical tool of Google allowing to measure the audience of the website.'}
            /*
            {name: 'gatsby-gdpr-facebook-pixel', editable: true, default: true, title: 'Facebook Pixel', text: 'Facebook Pixel is a statistical tool of Facebook allowing to measure the audience of the website.'}
            */
        ]}>
            <p>This site uses cookies. By continuing to browse the site, you are agreeing to our use of cookies. Read our <a href="https://us.pharmaessentia.com/privacy-notice/">Privacy Policy</a> to learn more.</p>
        </CookieNotice>
        </div>
    )
}
