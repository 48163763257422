import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import ConfirmExit from "./ConfirmExit"
import ConfirmHCP from "./ConfirmHCP"
import { useSiteMetadata } from "../../hooks/use-site-metadata"
import CookieBanner from "./CookieBanner"
import '../../components/styles/safari-hacks.css'

export default function Layout({ 
  children,
  id='',
  active = null,
  title,
  description,
  socialTitle,
  socialDescription,
  pathname }) {

  const { title: defaultTitle, description: defaultDescription, image, siteUrl, socialTitle: defaultSocialTitle, socialDescription: defaultSocialDescription } = useSiteMetadata()

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    socialTitle: socialTitle || defaultSocialTitle,
    socialDescription: socialDescription || defaultSocialDescription
  }

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="og:title" content={seo.socialTitle}/>
      <meta name="og:description" content={seo.socialDescription}/>
      <meta name="og:image" content={seo.image}/>
      <meta name="og:url" content={seo.url}/>
      <meta name="og:type" content="website"/>
      <link rel="icon" href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>" />
      <div id={id}>
        <ConfirmHCP>
          <ConfirmExit>
              <Header active={active}/>
              <div id='content-wrapper'>
                  {children}
              </div>
              <Footer/>
              <CookieBanner/>
          </ConfirmExit>
        </ConfirmHCP>

      </div>
    </>
  )
}