import React from 'react'
import { useEffect } from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'

export default function Header({ active = null }) {

  const data = useStaticQuery(graphql`
          query SiteNavigation {
            allDataYaml(filter: {yamlId: {eq: "navigation"}}) {
              nodes {
                buttons {
                  id
                  label
                  url
                  subitems {
                    id
                    label
                    url
                  }
                }
              }
            }
          }
        `),
        navData = data.allDataYaml.nodes[0].buttons

  //console.log(data.allDataYaml.nodes[0])

  useEffect(() => {
    const activeId = (() => {
            for(let i = 0, len = navData.length; i < len; i++) {
              const item = navData[i],
                    itemId = item.id,
                    subitems = item.subitems
              
              if(active === itemId) return 'nav-' + itemId
              if(subitems) {
                for(let j = 0, jLen = subitems.length; j < jLen; j++){
                  if(active === subitems[j].id) return 'nav-' + itemId
                }
              }
            }
            return null
          })(),
          header = document.querySelector('header'),
          menuToggle = document.getElementById('menu-toggle'),
          dropDowns = document.querySelectorAll('[data-dropdown="header"]'),
          contentWrapper = document.getElementById('content-wrapper')

    //  menu button click and frees content-wrapper
    menuToggle.addEventListener('click', (e)=>{
      header.classList.toggle('open') 
      contentWrapper.classList.toggle('no-scroll')

      if(header.classList.contains('open')) return
      for(let i = 0, len = dropDowns.length; i < len; i++){
        dropDowns[i].parentNode.classList.remove('open')
      }
    })

    //  add our menu drowDown functionality
    for(let i = 0, len = dropDowns.length; i < len; i++) {
      const dropDown = dropDowns[i]
      dropDown.addEventListener('click', (e)=>{
        e.target.parentNode.classList.toggle('open')
        contentWrapper.classList.toggle('no-scrol')
      })
    }
    
    //  style our active drop-down
    activeId && document.getElementById(activeId).classList.add('active')
  }, [])

  return (
    <header>

      <div className='bar-notice'>
        <nav>
          <p>FOR US HEALTHCARE PROFESSIONALS ONLY</p>
          <div>
            <Link
            className='hide-mobile margin-right-fs-txt-leading'
            to='/resources/ae-reporting'
            >Report an Adverse Event</Link>
            <Link
            to='/resources/contact-medical-information'
            >Contact Us</Link>
          </div>
        </nav>
      </div>

      <div className='bar-primary'>
        <nav>
          <span className='logo-bar'>
            <Link
              id='logo-home'
              to="/"
            >
              <img src='/svg/pharmaessentia-white-logo.svg' alt='PharmaEssentia logo'/>
            </Link>
            <button id="menu-toggle" className='toggle'>
              <span className='top'></span>
              <span className='middle'></span>
              <span className='bottom'></span>
            </button>
          </span>
          <ul>
            { 
              navData.map((item, index)=>(
                item.subitems
                ? 
                <li key={index + 'li'} id={'nav-' + item.id}>
                    <Link
                      key={index + 'link'}
                      to='#'
                      className='top-level'
                      data-dropdown='header'
                      dangerouslySetInnerHTML={{__html: item.label}}
                      />
                    <ul key={index + 'ul'}>
                    {
                      item.subitems.map((subitem, subindex) => (
                        <li key={subindex + 'li'} id={'nav-'+subitem.id}>
                          <Link
                            key={subindex + 'link'}
                            to={subitem.url}
                            dangerouslySetInnerHTML={{ __html: subitem.label }}
                          />
                        </li>
                      ))
                    }
                    </ul>
                </li>
                : 
                <li key={index + 'li'} id={'nav-' + item.id}>
                  <Link
                  className='top-level'
                  key={index + 'link'}
                  to={item.url}
                  dangerouslySetInnerHTML={{__html: item.label}}
                  />
                </li>
              ))
            }
          </ul>
        </nav>
      </div>
  </header>
  )
}
