import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import '../components/styles/index.scss'
import { Link } from 'gatsby'
import Layout from '../components/common/Layout'

export default function Home() {

  return (
    <Layout
    id='index'
    title='PharmaEssentia Medical Affairs'
    description='PharmaEssentia Medical Affairs provides comprehensive educational, clinical, and scientific information to healthcare professionals about PharmaEssentia’s therapies and areas of interest.'
    socialTitle='PharmaEssentia Medical Affairs'
    socialDescription='PharmaEssentia Medical Affairs provides comprehensive educational, clinical, and scientific information to healthcare professionals about PharmaEssentia’s therapies and areas of interest.'
    >
      <section id='hero'>
          <span className='wrapper'>
            <StaticImage
                src='../raster/blood-cell-background.png'
                alt='Green blood cell background'
                placeholder='none'
                />
          </span>
          <div className='inner white'>
            <div className='row'>
              <div className='col'>
                <h1>PharmaEssentia <span>Medical Affairs</span></h1>
              </div>
              <span className='divider no-margin'></span>
              <div className='col'>
                <p>The PharmaEssentia Medical Affairs team is a strategic thought partner dedicated to providing comprehensive educational, clinical, and scientific information about PharmaEssentia therapies and areas of interest, and engaging in meaningful discussions with healthcare professionals and key stakeholders about our pipeline and the unmet medical needs in myeloproliferative neoplasms.<br/><br/>The transformative and actionable insights gained through scientific exchange lead to the development of innovative <span className='no-break'>evidence-generation</span> strategies and educational opportunities.</p>
              </div>
            </div>
            <p>This site may include information on investigational products or uses that have not been approved by the US FDA. PharmaEssentia does not support, endorse, or recommend the unapproved use of any compound. This site includes information on clinical trials for approved and investigational compounds and indications. This information is not intended to be medical advice. By using this website, you confirm the unsolicited nature of your request.</p>
          </div>
      </section>
      <section>
        <div className='inner'>
          <h2 className='text-align-center'><span>Learn more</span> about PharmaEssentia Medical Affairs</h2>
          <div className='row'>
            <Link className='row cta' to='/pipeline'>
              <div className='col'>
                <h3>Explore our <span>clinical trials</span></h3>
                <p>across myeloproliferative neoplasms</p>
              </div>
              <div className='flex-col-centered width-max-content'><span className='triangle'></span></div>
            </Link>
            <span className='divider no-margin'></span>
            <Link className='row cta' to='/medical-information'>
              <div className='col'>
                <h3>Search our <span>product information</span></h3>
              </div>
              <div className='flex-col-centered width-max-content'><span className='triangle'></span></div>
            </Link>
            <span className='divider no-margin'></span>
            <Link className='row cta' to='/resources/publications'>
              <div className='col'>
                <h3>Review our <span>data</span></h3>
                <p>in our publications and congress presentations</p>
              </div>
              <div className='flex-col-centered width-max-content'><span className='triangle'></span></div>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}



